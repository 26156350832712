<template>
  <div>
    <CCardHeader>
      <CButton type="button" size="sm" color="info" @click="goBack"><CIcon name="cil-chevron-left" /></CButton>
    </CCardHeader>
    <CCardBody>
      <CForm id="submit" @submit.prevent="formSubmit">
        <CRow>
          <CCol class="col-12 col-sm-6">
            <CInput
              v-model.trim="formData.name"
              :label="$t('creatives.name')"
              size="sm"
              :class="{ 'is-invalid': $v.formData.name.$error }"
            />
            <small v-if="$v.formData.name.$error" class="form-error">{{ $t('errors.field_required') }}</small>
          </CCol>

          <CCol class="col-12 col-sm-6">
            <div class="form-group datepicker-wrap p-0">
              <label for="datepicker" class="col-form-label-sm datepicker">
                {{ $t('creatives.action_time') }}
              </label>
              <date-picker
                v-model="formData.dates"
                :clearable="false"
                :input-attr="{ id: 'datepicker' }"
                :class="{ 'is-invalid': $v.formData.dates.$error }"
                type="date"
                range
                format="DD.MM.YYYY hh:mm:ss"
                range-separator=" - "
                time-title-format="DD.MM.YYYY"
              ></date-picker>
            </div>
            <small v-if="$v.formData.dates.$error" class="form-error">{{ $t('errors.field_required') }}</small>
          </CCol>
        </CRow>

        <div class="form-group">
          <label class="col-form-label-sm" for="type">{{ $t('form.campaign') }}</label>
          <multiselect
            id="campaign"
            v-model="formData.campaignId"
            :class="{ 'is-invalid': $v.formData.campaignId.$error }"
            :placeholder="$t('form.campaign')"
            :options="mappedCampaignList"
            :show-labels="false"
            :disabled="!isCreateForm"
            track-by="value"
            label="label"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
          <small v-if="$v.formData.campaignId.$error" class="form-error">{{ $t('errors.field_required') }}</small>
        </div>

        <div class="form-group">
          <label class="col-form-label-sm" for="type">{{ $t('creatives.creative_type') }}</label>
          <multiselect
            id="type"
            v-model="formData.type"
            :class="{ 'is-invalid': $v.formData.type.$error }"
            :placeholder="$t('creatives.creative_type')"
            :options="creativeTypes"
            :show-labels="false"
            track-by="type"
            label="name"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
          <small v-if="$v.formData.type.$error" class="form-error">
            {{ $t('errors.field_required') }}
          </small>
        </div>

        <div class="form-group">
          <label class="col-form-label-sm" for="type">{{ $t('creatives.match') }}</label>
          <multiselect
            id="match"
            v-model="formData.sportFeedMatchId"
            :placeholder="$t('creatives.match')"
            :options="filteredMatchList"
            :show-labels="false"
            :disabled="!isActiveSportFeedAvailable || !sportFeedActive"
            track-by="value"
            label="label"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
        </div>

        <CInput
          v-model.trim="formData.cpm"
          type="number"
          :label="$t('creatives.cpm')"
          size="sm"
          :class="{ 'is-invalid': $v.formData.cpm.$error }"
        />
        <small v-if="$v.formData.cpm.$error" class="form-error">{{ $t('errors.field_required') }}</small>

        <div class="form-group">
          <label class="col-form-label-sm" for="link-type">{{ $t('creatives.link_type') }}</label>
          <multiselect
            id="link-type"
            v-model="formData.linkType"
            :class="{ 'is-invalid': $v.formData.linkType.$error }"
            :placeholder="$t('creatives.link_type')"
            :options="linkTypes"
            :show-labels="false"
            track-by="id"
            label="name"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
          <small v-if="$v.formData.linkType.$error" class="form-error">
            {{ $t('errors.field_required') }}
          </small>
        </div>

        <CInput
          v-model.trim="formData.clickUrl"
          :label="$t('creatives.click_url')"
          size="sm"
          :class="{ 'is-invalid': $v.formData.clickUrl.$error }"
        />
        <small v-if="$v.formData.clickUrl.$error" class="form-error">{{ $t('errors.field_required') }}</small>

        <CRow class="mb-2">
          <CCol>
            <label for="active" class="mb-2 col-form-label-sm">{{ $t('creatives.active') }}</label>
            <CSwitch id="active" class="d-block" :checked.sync="formData.active" color="info" />
          </CCol>
        </CRow>
        <CRow class="mb-2">
          <CCol>
            <label for="sportFeedActive" class="mb-2 col-form-label-sm">{{ $t('creatives.sport_feed_active') }}</label>
            <CSwitch
              id="sportFeedActive"
              class="d-block"
              :disabled="!isActiveSportFeedAvailable"
              :checked.sync="sportFeedActive"
              color="info"
            />
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
    <CCardFooter>
      <CButton form="submit" type="submit" :disabled="isSending" size="sm" color="info">{{ buttonText }}</CButton>
      <CButton class="ml-2" type="button" size="sm" color="danger" @click="goBack">{{ $t('ui.cancel') }}</CButton>
    </CCardFooter>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { mapGetters, mapActions } from 'vuex';
import { GET_CREATIVE_TYPES, GET_LINK_TYPE_LIST } from '@/store/action-types/dictionary';
import { GET_MATCHES_LIST, GET_MATCH_BY_ID } from '@/store/action-types/creatives';
import { GET_CAMPAIGN_LIST } from '@/store/action-types/campaigns';
import { filtersDefaultLimit, mostbetLink } from '@/helpers/const';
import { required } from 'vuelidate/lib/validators';

const datesValidator = (value) => {
  return value[0] !== null;
};

const availableSportFeedBannerTypes = [
  'banner_740_100',
  'topline',
  'catfish',
  'banner_240_400',
  'banner_728_90',
  'banner_320_50',
  'banner_320_100',
  'banner_600_200',
  'banner_600_300',
];

export default {
  name: 'CreativeForm',
  components: {
    DatePicker,
  },
  props: {
    isSending: {
      type: Boolean,
      required: true,
    },
    initialValues: {
      type: Object,
      required: true,
    },
  },
  validations: {
    formData: {
      campaignId: {
        required,
      },
      name: {
        required,
      },
      type: {
        required,
      },
      cpm: {
        required,
      },
      clickUrl: {
        required,
      },
      linkType: {
        required,
      },
      dates: {
        datesValidator,
      },
    },
  },
  data() {
    return {
      sportFeedActive: this.initialValues.sportFeedActive,
      formData: {
        campaignId: this.initialValues.campaignId,
        name: this.initialValues.name,
        type: this.initialValues.type,
        active: this.initialValues.active,
        moderated: this.initialValues.moderated,
        approved: this.initialValues.approved,
        cpm: this.initialValues.cpm,
        linkType: this.initialValues.linkType,
        clickUrl: this.initialValues.clickUrl,
        media: this.initialValues.media,
        dates: [this.initialValues.dateStart, this.initialValues.dateEnd],
        sportFeedMatchId: this.initialValues.sportFeedMatchId,
      },
    };
  },
  computed: {
    ...mapGetters('dictionary', ['creativeTypes', 'linkTypes']),
    ...mapGetters('campaigns', ['mappedCampaignList']),
    ...mapGetters('creatives', ['matchesList']),
    buttonText() {
      return this.isCreateForm ? this.$t('ui.create') : this.$t('ui.update');
    },
    isCreateForm() {
      return this.$route.name === 'creative-create';
    },
    isActiveSportFeedAvailable() {
      return availableSportFeedBannerTypes.includes(this.formData.type?.type);
    },
    filteredMatchList() {
      return this.matchesList.filter(
        ({ disabled, value }) => !disabled && value !== this.initialValues.sportFeedMatchId
      );
    },
  },
  watch: {
    creativeTypes(types) {
      this.setSelectedValues(types, 'type', 'type');
    },
    linkTypes(linkTypes) {
      this.setSelectedValues(linkTypes, 'linkType', 'id');
    },
    mappedCampaignList(campaigns) {
      this.setSelectedValues(campaigns, 'campaignId', 'value');
    },
    matchesList(matchesList) {
      this.setSelectedValues(matchesList, 'sportFeedMatchId', 'value');
    },
  },
  mounted() {
    this.GET_CAMPAIGN_LIST({ limit: filtersDefaultLimit });
    this.GET_CREATIVE_TYPES();
    this.GET_LINK_TYPE_LIST();
    this.GET_MATCHES_LIST();
    this.GET_MATCH_BY_ID(this.initialValues.sportFeedMatchId);
  },
  methods: {
    ...mapActions('dictionary', [GET_CREATIVE_TYPES, GET_LINK_TYPE_LIST]),
    ...mapActions('campaigns', [GET_CAMPAIGN_LIST]),
    ...mapActions('creatives', [GET_MATCHES_LIST, GET_MATCH_BY_ID]),
    goBack() {
      this.$router.go(-1);
    },
    setSelectedValues(list, field, listProp) {
      const selectedValue = list.find((item) => item[listProp] === this.formData[field]);

      if (selectedValue) {
        this.formData = { ...this.formData, [field]: selectedValue };
      }
    },
    formSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const formData = {
        campaignId: this.formData.campaignId.value,
        name: this.formData.name,
        type: this.formData.type.type,
        active: this.formData.active,
        moderated: this.formData.moderated,
        approved: this.formData.approved,
        cpm: this.formData.cpm,
        dateStart: this.formData.dates[0].toISOString(),
        dateEnd: this.formData.dates[1].toISOString(),
        linkType: this.formData.linkType.id,
        clickUrl: this.formData.clickUrl,
        media: this.formData.media,
        sportFeedMatchId:
          this.sportFeedActive && this.isActiveSportFeedAvailable && this.formData.sportFeedMatchId?.value
            ? this.formData.sportFeedMatchId.value
            : null,
      };

      this.$emit('formSubmit', formData);
    },
  },
};
</script>
